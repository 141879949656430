import { Dropdown, IconButton, IDropdownOption, Label, Separator, Stack, TextField } from '@fluentui/react'
import React, { useState, useRef, useEffect } from 'react'
import { getImage, IField, IMetadata, IImage, IUploadFileResponse, uploadImage } from '../services/assetServices';
import { columnProps, labelColumnStyle, valueColumnStyle } from './styles/FormsStyles';
import { errorMessageAtom, isInProgressAtom } from '../atoms/messageBarAtoms';
import { useSetAtom } from 'jotai';
import { IValidationError } from './ValidationHelper';

export interface IOtherInfoProps {
  metadata: IMetadata;
  otherInfo: string;
  isSaved: React.MutableRefObject<boolean>;
  validationErrors?: IValidationError[];
  onOtherInfoChanged: (newValue: string) => void;
}

export interface IImageSelectorProps {
  value: string;
  src: string;
  onUpload: (url: string) => void;
}

const ImageSelector = (params: IImageSelectorProps) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isSelected, setIsSelected] = useState(false);

  const fileUploadRef = useRef(null);

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const handleUploadClick = () => {
    if (!selectedFile?.name) {
      return;
    }
    const blob: Blob = selectedFile as Blob;
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const array = reader.result;
      const abortController = new AbortController();
      uploadImage(abortController, selectedFile?.name, array as ArrayBuffer)
        .then((data: IUploadFileResponse) => {
          params.onUpload(data.url);
        });
    })
    reader.readAsDataURL(blob);
  };

  return (
    <Stack>
      <img src={params.src} alt="" style={{ width: 250 }} />
      <Stack horizontal>
        <IconButton iconProps={{ iconName: 'FabricFolder' }} onClick={() => {
          (fileUploadRef?.current as unknown as HTMLInputElement).click()
        }} />
        <input type="file" name="file"
          ref={fileUploadRef} onChange={changeHandler} style={{ display: "none" }} />
        <TextField value={selectedFile?.name ?? params.value} readOnly disabled styles={{ root: { width: 186 } }} />
        <IconButton iconProps={{ iconName: 'CloudUpload' }} disabled={!isSelected} onClick={() => handleUploadClick()} />
      </Stack>
    </Stack>)
}

const OtherInfo = (params: IOtherInfoProps) => {
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const [otherInfo, setOtherInfo] = useState(params.otherInfo);

  const getLookupOption = (metadata: IMetadata, field: IField): IDropdownOption<any>[] => {
    const lookup = metadata.lookups.filter(l => l.name === field.lookupList)[0];
    if (lookup) {
      const lookupValues = lookup.values;
      return lookupValues.map(v => ({ key: v.key, text: v.value }));
    }
    return [];
  }

  const handleOtherInfoChange = (fieldName: string, value?: string) => {
    if (value) {
      setOtherInfo((prev) => {
        const newOtherInfo = prev ? JSON.parse(prev) : {};
        newOtherInfo[fieldName] = value;
        params.onOtherInfoChanged(newOtherInfo);
        return JSON.stringify(newOtherInfo);
      })
    }
  };

  const [images, setImages] = useState<Map<string, string>>()

  useEffect(() => {
    if (params.otherInfo) {
      setOtherInfo(params.otherInfo)
    } else {
      setOtherInfo('{}');
    }
  }, [params]);

  const fetchImages = async (imageFields: IField[]) => {
    const imagePaths: string[] = [];
    imageFields.map((field) => imagePaths.push(JSON.parse(otherInfo ?? '')[field.name]));
    const abortController = new AbortController();

    try {
      const newImages: Map<string, string> = new Map(images ?? []);
      await Promise.all(imagePaths.filter(image => image !== "").map(async (image) => {
        if (image && !newImages?.has(image)) {
          await getImage(abortController, image, "attachments").then((data: IImage) => {
            const imageBase64 = data.imageContent;
            newImages?.set(image, `${imageBase64}`);
          })
        }
      }));
      setImages(newImages);
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    }
  }

  useEffect(() => {
    const imageFields = params.metadata?.fields.filter(f => f.fieldType === "Image");
    if (imageFields.length === 0) {
      return;
    }
    fetchImages(imageFields);
  }, [otherInfo])

  return (
    <Stack {...columnProps}>
      <Separator>Other Info</Separator>
      {(!params.metadata || !params.otherInfo) ? <></> : params.metadata?.fields.map(field =>
        <Stack horizontal>
          <Label style={labelColumnStyle}>{field.label}</Label>
          {(field.fieldType === "Number" || field.fieldType === "String") ?
            <TextField style={valueColumnStyle}
              onChange={(_, newValue) => handleOtherInfoChange(field.name, newValue)}
              type={field.fieldType === "Number" ? "number" : "string"}
              value={JSON.parse(otherInfo ?? '')[field.name]}
              errorMessage={params.validationErrors?.find(e => e.fieldName === field.name)?.message}
              readOnly={params.isSaved.current} />
            : (field.fieldType === "Lookup") ?
              <Dropdown style={valueColumnStyle} options={getLookupOption(params.metadata, field)}
                selectedKey={JSON.parse(otherInfo ?? '')[field.name]}
                onChange={(_, option) => handleOtherInfoChange(field.name, option?.key.toString())}
                errorMessage={params.validationErrors?.find(e => e.fieldName === field.name)?.message}
                disabled={params.isSaved.current} />
              : (field.fieldType === "Image") ?
                <ImageSelector
                  value={JSON.parse(otherInfo ?? '')[field.name]}
                  src={images?.get(JSON.parse(otherInfo ?? '')[field.name]) ?? ""}
                  onUpload={(url: string) => handleOtherInfoChange(field.name, url)}
                />
                : <></>}
        </Stack>
      )}
    </Stack>
  )
}

export default OtherInfo